.top-nav {
  position: fixed;
  top: 0;
  left: 12.5em; /* distance from AsideNav */
  width: calc(100% - 12.5em);
  height: 3em;
  display: flex;
  z-index: 3;
}

.top-nav .gray-square {
  width: 3em;
  background-color: rgb(47, 47, 47);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.top-nav .gray-square img {
  height: 50%;
  width: 50%;
}

.top-nav .uri-div {
  width: calc(100% - 3em); /* width of first div (dark gray square) */
  background-color: rgb(36, 36, 36);
  display: flex;
  align-items: center;
  padding-left: 1em;
  box-sizing: border-box;
}

.top-nav pre {
  color: white;
  /* margin: 0 0 0 0.5em; */
  font-size: 1.5em;
  transition: 0.3s;
  display: inline;
}

.top-nav .pre-link:hover {
  text-decoration: underline;
}