.gallery-container {
  position: fixed;
  top: 0;
  left: 0;
  /* width: 100vw;
  height: 100vh; */
  width: 100%;
  height: 100%;
  z-index: 10;
}

.gallery-container .under-nav {
  display: flex;
  justify-content: space-between;
  background: rgb(29, 29, 29);
  height: 3em;
}

.gallery-container body {
  display: grid;
  grid-template-columns: 3em auto 3em;
  height: 100%;
  width: 100%;
  margin-top: 3em;
}

.gallery-container .gallery-side {
  background: rgb(29, 29, 29);
}

.gallery-container .view-toggle {
  width: 30em;
  align-items: center;
}

.view-toggle span {
  color: white;
}

.gallery-container .carousel-image {
  /* display: inline-flex; */
}

.image-carousel-container::-webkit-scrollbar {
  width: 10px;
}

.image-carousel-container::-webkit-scrollbar-track {
  background: linear-gradient(to right, rgb(29, 29, 29), rgb(113, 113, 113));
  border-radius: 3em;
  height: 1em;
}

.image-carousel-container::-webkit-scrollbar-thumb {
  background: rgb(65, 65, 65);
  width: 64px;
  -webkit-width:5;
  border-radius: 4em;
  border: 2px solid rgb(144, 144, 144);
  height: 1em;
}

/* .image-carousel-container::-webkit-scrollbar-thumb:hover {
  background: #555;
} */

.gallery-container .carousel-image img {
  width: 100%;
  height: 100%;
}

.gallery-container .image-carousel-container {
  width: 100%;
  overflow-x: scroll;
}

.gallery-container .compare-view {
  overflow: scroll;
}

.gallery-container .a {
  background: rgb(29, 29, 29);
  overflow: scroll;
}

.gallery-container .year-month {
  height: 4em;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.gallery-container .year-month p {
  color: white;
}

.gallery-container .image-carousel {
  display: inline-flex;
  overflow: scroll;
  /* width: 100%; */
}

.gallery-container .images {
  height: calc(100% - 4em);
  overflow: scroll;
  background: rgb(29, 29, 29);
  padding-bottom: 3em;
  box-sizing: border-box;
  position: relative;
}

.gallery-container .images img {
  cursor: pointer;
}

.gallery-container .month-row {
  width: 100%;
}

/* .gallery-container .view-toggle {
  padding: 0 1em;
  box-sizing: border-box;
} */

.gallery-container .view-toggle span {
  margin: 0 1em;
}

.view-toggle .timeline-btn {
  border-radius: 3em;
  padding: 0 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.view-toggle .timeline-btn p {
  color: white;
  margin: 0;
}

.view-toggle .toggle-btn {
  background: rgb(65, 65, 65);
  box-sizing: border-box;
  border-radius: 3em 0 0 3em;
  width: 10em;
}

.view-toggle .grid-btn {
  background: rgb(65, 65, 65);
  box-sizing: border-box;
  border-radius: 0 3em 3em 0;
  width: 10em;
}

.view-toggle .selected-btn {
  border: 1px solid rgb(138, 138, 138);
  background: black;
}

.zoom-slider-container {
  width: 20em;
  display: flex;
  padding: 0.5em;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  /* background: rgb(109, 109, 109); */
}

.gallery-container .top-nav {
  left: 0;
  width: 100%;
}

.gallery-container .x-box {
  background: rgb(47, 47, 47);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3em;
  cursor: pointer;
}

.gallery-container .x-box img {
  width: 50%;
  height: auto;
}

.gallery-container .grid-images {
  display: grid;
  overflow: hidden;
  margin: 4em 0 0;
  /* grid-auto-flow: column;
  grid-auto-columns: auto; */
}

.gallery-container .grid-image img {
  box-sizing: border-box;
}

.gallery-container .grid-image p {
  color: white;
  margin: 0.5em 0 2em;
}

.gallery-container .selected-image {
  border: 3px solid white;
}

.galery-side-right {
  background: rgb(36, 36, 36);
}

.gallery-side-right > div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3em;
  cursor: pointer;
}

.gallery-container .selected-view {
  background: black;
}

.gallery-container .carousel-image img {
  box-sizing: border-box;
}

/* .compare-render-container {
  height: 100%;
  overflow: scroll;
} */

.side-by-side-image-container {
  background: rgb(29, 29, 29);
  /* height: calc(100% - 7em); */
  overflow: scroll;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.side-by-side-image-container > div {
  max-width: calc(100vh - 12em);
}

.side-by-side-image-container img {
  width: 100%;
  height: auto;
} 

.side-by-side-image-container p {
  color: white;
}

.gallery-container .gallery-thumbnails {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: 4em;
  background: rgb(29, 29, 29);
  grid-gap: 0.5em;
  margin-bottom: 5em;
}

.gallery-thumbnails > div {
  width: 100%;
  display: grid;
  grid-template-columns: 4em minmax(4em, auto) 4em;
  position: relative;
}

.gallery-thumbnails .white-line {
  position: absolute;
  height: 50%;
  width: 100%;
  border-bottom: 1px solid rgb(140, 140, 140);
}

.gallery-thumbnails .gallery-1, .gallery-thumbnails .gallery-2 {

}

.gallery-thumbnails > div > div {
  display: flex;
}

.gallery-thumbnails .second-third {
  justify-content: center;
  overflow: hidden;
}

.gallery-thumbnails .second-third > div {
  display: flex;
  overflow: scroll;
}

.gallery-thumbnails .third-third {
  justify-content: flex-end;
}

.gallery-thumbnails img {
  height: 4em;
  box-sizing: border-box;
  z-index: 2;
  cursor: pointer;
}

.gallery-container .tooltip {
  animation-name: slideInRight;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  position: fixed;
  right: 4em;
  top: 6em;
}

.gallery-container .tooltip p {
  color: white;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}