body {
  margin: 0;
  font-family: 'Work Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(240, 240, 240);
}

body::-webkit-scrollbar { 
  display: none; 
}

body {
  -ms-overflow-style: none;  
  scrollbar-width: none;  
}

#root {
  width: 100%;
  height: 100%;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.page {
  width: 100%;
  height: 100%;
}

.z-2 {
  z-index: 2;
}

header {
  top: 3em;
  left: 12.5em; /* width of AsideNav */
  position: absolute;
  width: calc(100% - 12.5em);
}

.patient-page header, .test-page header {
  margin: 1.5em;
  width: calc(100% - 15.5em); /* width of aside + margin */
}

main {
  left: 12.5em;
  top: 3em;
  position: absolute;
  padding: 1.5em;
  box-sizing: border-box;
  width: calc(100% - 12.5em);
  height: calc(100% - 3em);
}

.giant-circle {
  height: 200vh;
  width: 200vh;
  border-radius: 100%;
  background: rgba(142, 241, 255, 0.8);
  position: fixed;
  top: 3em;
  right: 0;
  transform: translate(50%, 0%) scale(1);
  z-index: 5;
}

.grow-circle {
  animation: grow-circle 0.3s;
  animation-fill-mode: forwards;
  transform: translate(50%, 0%);
}

.shrink-circle {
  animation: shrink-circle 0.3s;
  animation-fill-mode: forwards;
}

.dashboard main, .m-proc main {
  top: 9em;
  height: calc(100% - 9em);
}

.patient-page main, .test-page main, .add-patient main, .settings-page main {
  top: 9.5em;
  height: calc(100% - 9.5em);
  display: flex;
}

.settings-page main {
  overflow: scroll;
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: minmax(0, auto);
}

.patient-page-main {
  display: grid !important;
  grid-template-columns: 20.25em minmax(auto, 50vh) 29.5em;
  grid-template-rows: minmax(0, 1fr);
  width: calc(100% - 12.5em);
  height: calc(100% - 9.5em);
  overflow: scroll;
}

aside {
  width: 15em;
  margin-right: 1.5em;
}

.patient-page aside, .test-page aside, .test-upload-page aside {
  width: 18.75em;
}

.patient-page .menu, .test-page .menu {
  position: fixed;
  right: 0;
  bottom: 1.5em;
  margin: 2.5em;
  margin-right: 1.5em;
  z-index: 6;
}

.patient-page footer {
  bottom: 0;
}

a {
  text-decoration: none;
  color: black;
}

.patient-header-container {
  position: fixed;
  top: 3em;
  left: 12.5em; /* width of AsideNav */
  width: calc(100% - 12.5em);
  /* background-color: white; */
  display: flex;
  height: 7em;
  width: 100%;
  min-width: 37em;
  padding: 5px 1.5em 5px 5px;
  box-sizing: border-box;
  z-index: 9;
}

textarea {
  padding: 1em;
  box-sizing: border-box;
  border: none;
  background-color: white;
  -webkit-appearance: none !important;
  border-radius: 0 !important;
  resize: none;
}

.underline {
  text-decoration: underline;
}

.font-small {
  font-size: 0.65em;
}

.font-medium {
  font-size: 1.5em;
}

.small-btn {
  border: 1px solid black;
  border-radius: 3em;
  padding: 5px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2em;
  box-sizing: border-box;
  cursor: pointer;
}

.small-btn p {
  margin: 0;
  font-size: 0.65em;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex {
  display: flex;
}

.justify-space-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-start {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

.w100 {
  width: 100%;
}

.w50 {
  width: 50%;
}

.margin-0 {
  margin: 0;
}

.margin-bottom-05em {
  margin-bottom: 0.5em;
}

.margin-bottom-1em {
  margin-bottom: 1em;
}

.margin-bottom-2em {
  margin-bottom: 2em;
}

.margin-bottom-3em {
  margin-bottom: 3em;
}

.margin-bottom-4em {
  margin-bottom: 3em;
}

.margin-top-0 {
  margin-top: 0;
}

.margin-right-10px {
  margin-right: 10px;
}

.gray {
  color: #8B8B8B;
}

.bold {
  font-weight: 600;
}

.upperCase {
  text-transform: uppercase;
}

.invisible {
  display: none;
}

.gray-screen {
  background: rgba(50, 50, 50, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
}

.white-screen {
  background: rgba(255, 255, 255, 0);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 4;
}

/* animations */

.expand-down {
  animation: expand-down 0.3s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

@keyframes expand-down {
  from {
    max-height: 0;
  }

  to {
    max-height: 300px;
  }
}

.collapse-up {
  animation: collapse-up 0.3s;
  animation-fill-mode: forwards;
  overflow: hidden;
}

.slideInUp {
  animation: slideInUp;
  animation-duration: 300;
  animation-fill-mode: forwards;
}

.slideOutDown {
  animation: slideOutDown;
  animation-duration: 300;
  animation-fill-mode: forwards;
}

@keyframes collapse-up {
  from {
    max-height: 300px;
  }

  to {
    max-height: 0;
  }
}

.slideInLeft {
  animation: slideInLeft 0.3s;
  animation-fill-mode: forwards;
}

.slideOutRight {
  animation: slideOutRight 0.3s;
  animation-fill-mode: forwards;
}

@keyframes slideInLeft {
  from {
    transform: translateX(500%);
    opacity: 0;
  }
  
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0%);
    opacity: 1;
  }
  
  to {
    transform: translateX(500%);
    opacity: 0;
  }
}

@keyframes grow-circle {
  from {
    transform: translate(50%, 0%) scale(0);
  }

  to {
    transform: translate(50%, 0%) scale(1);
  }
}

@keyframes shrink-circle {
  from {
    transform: translate(50%, 0%) scale(1);
  }

  to {
    transform: translate(50%, 0%) scale(0);
  }
}

@keyframes slideInUp {
  from {
    transform: translate(0, 100%);
    opacity: 0;
  }

  to {
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slideOutDown {
  from {
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    transform: translate(0, 100%);
    opacity: 0;
  }
}

