#zoomView-body{
  background-color: rgb( 29, 29, 29);
  display: flex;
  grid-template-columns: 3em auto 3em;
  height: 100%;
  width: 100%;
  margin-top: 3em;
  flex-direction: column;
}

#zoomView-body .under-nav {
  position: fixed;
  top: 3em;
  width: 100%;
}

.zoomView-images-container {
  position: fixed;
  top: 6em;
  overflow: scroll;
  width: 100%;
  height: calc(100% - 6em);
}

.zoomView-images-container::-webkit-scrollbar {
  display: none;
  scrollbar-width: none;
}

#zoomView-body .zoom-slider-container {
  margin-right: 1em;
}

.zoomView-gallery-container {
  overflow: scroll;
}

.m-proc-image{
  padding: 2px;
}

.zoomView-images {
  display: flex;
  justify-content: center;
}

#m-proc-row{
  margin: .5em 0 0;
  overflow: scroll;
  display: flex;
}

/* #m-proc-row::-webkit-scrollbar {
  width: 10px;
}

#m-proc-row::-webkit-scrollbar-track {
  background: linear-gradient(to right, rgb(29, 29, 29), rgb(113, 113, 113));
  border-radius: 3em;
  height: 1em;
}

#m-proc-row::-webkit-scrollbar-thumb {
  background: rgb(65, 65, 65);
  width: 64px;
  -webkit-width:5;
  border-radius: 4em;
  border: 2px solid rgb(144, 144, 144);
  height: 1em;
} */

.view-toggle {
  color: white;
  margin-left: 48px;
}

.images {
  margin-left: 48px;
}

/* .zoomView-slider-container {
  width: 20em;
  display: flex;
  padding: 0.5em;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  position: relative;
  right: -77%;
} */