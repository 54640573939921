/* .patient-page main {
  min-width: 62.5em;
  display: grid;
  grid-template-rows: minmax(0, 1fr) 4em;
  grid-template-columns: auto minmax(0, 3fr) 2fr 1.875em;
  grid-template-areas:
  'a b c .';
} */

.filter-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 2.5em;
  background: white;
  padding: 0 0.5em;
  border-left: 3px solid white;
  transition: 0.2s;
}

.patient-page .tab {
  cursor: pointer;
}

.selected-tab {
  border-color: black;
}

.filter-div .title {
  display: flex;
  align-items: center;
  height: 100%;
}

.filter-div p {
  margin: 0;
}

.filter-div .font-small {
  margin-right: 5px;
}

.filter-div img {
  height: 70%;
  width: auto;
  margin-right: 0.5em;
}

.img-circle {
  height: 2em;
  width: 2em;
  border-radius: 100%;
  border: 1px solid black;
  padding: 5px;
  box-sizing: border-box;
}

.img-circle img {
  height: 100%;
  width: auto;
}

.condition {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 2em;
  padding: 0 1em;
  cursor: pointer;
}

.condition div {
  height: 2em;
  width: 2em;
}

.condition p {
  margin: 0;
}

.condition img {
  height: 85%;
  width: auto;
}

.accuracy-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0.75em;
  height: 3em;
}

.accuracy-div span {
  font-size: 0.65em;
}

.wire-person-div {
  /* width: 20em; */
  width: 100%;
}

.wire-person-container {
  position: relative;
  min-width: 7em;
}

.axis-container {
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;
}

.wire-person-div img {
  width: 100%;
  height: auto;
}

.sharePatientModal {
  position: fixed;
  left: 50%;
  top: 11em;
  /* transform: translate(50%, 50%); */
  border: 1px solid black;
  border-radius: 1em;
  z-index: 4;
  padding: 1em;
  background-color: rgb(240, 240, 240);
  height: calc(100% - 15em);
}

.sharePatientModal .org-member-list {
  overflow: scroll;
  height: calc(100% - 17.5em);
  margin-bottom: 1em;
}

#y-axis {
  left: 0;
  top: 0;
  position: absolute;
  border-right: 0.2px solid rgb(145, 145, 145);
  width: 0%;
  height: 100%;
}

#x-axis {
  left: 0;
  top: 0;
  position: absolute;
  border-bottom: 0.2px solid rgb(145, 145, 145);
  width: 100%;
  height: 0%;
}

.qr-code-modal {
  padding: 1em;
  background-color: white;
  position: absolute;
  left: 50%;
  top: calc(50% - 3em);
  transform: translate(-50%, -50%);
  width: 256px;
  z-index: 10;
}

.patient-info-container {
  position: fixed;
  height: calc(100% - 3em);
  width: calc(100% - 12.5em);
  left: 12.5em;
  top: 3em;
  z-index: 2;
  padding: 8em 1.5em 0;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.9);
}

.patient-info {
  margin: 0 7em;
}

/* Media queries */
@media only screen and (max-width: 1200px) {
  aside {
    /* font-size: 12px; */
    min-width: 12em;
  }
}
